import styles from "./text-fit.module.scss";
import { useEffect, useRef } from "react";

export default function TextFit({ content, calculateBySibling, letterSpacing, zIndex }) {
    const elementRef = useRef(null);

    useEffect(() => {
        let toCalculateElement;
        if (calculateBySibling) {
            toCalculateElement = elementRef.current.previousSibling;
        }

        if (!toCalculateElement) {
            toCalculateElement = elementRef.current.parentElement;
        }

        if (toCalculateElement) {
            let scaleRatio = (toCalculateElement.offsetWidth - 20) / elementRef.current.offsetWidth; // 20 is padding left and right

            if (scaleRatio > 1) {
                scaleRatio = 1;
            }

            if (scaleRatio < 0.25) {
                scaleRatio = 0.25;
            }
            elementRef.current.style.transform = `scale(${ scaleRatio })`
        }
    }, [content, calculateBySibling])

    const getStyle = () => {
        const letterSpacingStyle = letterSpacing ? {
            letterSpacing: `${letterSpacing}px`
        } : {};
        const zIndexStyle = zIndex ? {
            zIndex: zIndex
        } : {};

        return {
            ...letterSpacingStyle,
            ...zIndexStyle
        };
    }

    return (
        <p className={ styles.textCode }
           ref={ elementRef }
           style={getStyle()}>
            { content }
        </p>
    )
}