import { AppStage } from "../model/app-stage";
import { BackgroundType } from "../model/background-type";
import { GiftDto } from "../model/view-model/gift-dto";

const AppActionType = {
    Reset: -1,
    SetAppStage: 0,
    SetBackground: 1,
    SetCode: 2,
    SetGift: 3
}

export const UpdateBackgroundAction = (backgroundType) => {
    return { type: AppActionType.SetBackground, backgroundType: backgroundType };
}

export const UpdateCodeAction = (code) => {
    return { type: AppActionType.SetCode, code: code };
}

export const UpdateAppStageAction = (newStage) => {
    return { type: AppActionType.SetAppStage, appStage: newStage }
}

export const UpdateGiftAction = (newGift) => {
    return { type: AppActionType.SetGift, gift: newGift }
}

export const ResetAppContextAction = () => {
    return {type: AppActionType.Reset}
}

export const DefaultAppContext = {
    appStage: AppStage.MainPage,
    backgroundType: BackgroundType.Default,
    code: "",
    gift: new GiftDto()
}

export const AppReducer = (state, action) => {
    switch (action.type) {
        case AppActionType.SetBackground: {
            return {
                ...state,
                backgroundType: action.backgroundType
            }
        }
        case AppActionType.SetCode: {
            return {
                ...state,
                code: action.code
            }
        }
        case AppActionType.SetAppStage: {
            return {
                ...state,
                appStage: action.appStage
            }
        }
        case AppActionType.SetGift: {
            return {
                ...state,
                gift: action.gift
            }
        }
        case AppActionType.Reset: {
            return DefaultAppContext;
        }
        default:
            return state;
    }
}