import React, {createRef, useContext, useEffect, useMemo, useState} from 'react';
import styles from './input-code-stage.module.scss'
import {AppContext, UpdateAppContext} from '../../service/app.context';
import {
    ResetAppContextAction,
    UpdateAppStageAction,
    UpdateCodeAction,
    UpdateGiftAction
} from '../../service/app.reducer';
import * as CommonConstant from '../../constants/common-constants'
import InputCode1 from "../../resource/image/Input/code1.png";
import InputCode2 from "../../resource/image/Input/code2.png";
import InputCode3 from "../../resource/image/Input/code3.png";
import InputCode4 from "../../resource/image/Input/code4.png";
import InputCode5 from "../../resource/image/Input/code5.png";
import StartButton from "../../resource/image/Input/StartButton.png";
import CloseButton from "../../resource/image/close.png";
import {AppStage} from '../../model/app-stage';
import CodeService from '../../service/code.service';

const inputCodeIndexes = [...Array(CommonConstant.MaximumCodeLength).keys()];
const initState = inputCodeIndexes.map(x => "");

export default function InputCodeStage() {

    const [code, setCode] = useState(initState);
    const [backSpacePressCount, setBackSpacePressCount] = useState(0);
    const inputRefs = useMemo(() => inputCodeIndexes.map(i => createRef()), []);
    const [displayWarningMessage, setDisplayWarningMessage] = useState("");

    const appState = useContext(AppContext);
    const updateAppState = useContext(UpdateAppContext);

    useEffect(() => {
        inputRefs[0]?.current?.focus();
    }, [inputRefs])

    const onChange = (event, index) => {
        const newValue = event.target.value;

        let newCode = [...code];
        newCode[index] = newValue;
        setCode(newCode);
        const codeString = newCode.reduce((previousValue, currentValue) => previousValue += currentValue, "");
        updateAppState(UpdateCodeAction(codeString));
        if (newValue) {
            focusInputElement(index + 1);
        }
    }

    const focusInputElement = (index) => {
        const element = inputRefs[index];
        if (element) {
            element.current?.focus();
        }
    }

    const onKeyUp = async (event, index) => {
        if (event.key === "Backspace") {
            setDisplayWarningMessage(CommonConstant.InputCodeWarningMessage.NoWarning);
            const currentCount = backSpacePressCount + 1;
            setBackSpacePressCount(currentCount)
            if (currentCount === 2) { //if press backspace twice => go back to previous input
                focusInputElement(index - 1);
                setBackSpacePressCount(0);
            }
            return;
        }

        if (event.key === "Enter") {
            await onPlayButtonClick();
        }
        setBackSpacePressCount(0);
    }

    const onKeyDown = (event) => {
        if (!CommonConstant.RegexCharacter.test(event.key) && event.key.length === 1) {
            event.preventDefault();
        }
    }

    const onCloseClick = () => {
        setCode(initState);
        updateAppState(ResetAppContextAction());
    }

    const onPlayButtonClick = async () => {
        if(!isValidInput()){
            setDisplayWarningMessage(CommonConstant.InputCodeWarningMessage.InvalidInput);
        }

        const response = await CodeService.getGiftOfCodeAsync(appState.code);

        if (response.warning) {
            setDisplayWarningMessage(response.warning);
            return;
        }

        updateAppState(UpdateAppStageAction(AppStage.Loading));
        updateAppState(UpdateGiftAction(response.gift));
        setDisplayWarningMessage(CommonConstant.InputCodeWarningMessage.NoWarning);
        setCode(initState);
    }

    const isValidInput = () => appState.code && appState.code.length === CommonConstant.MaximumCodeLength;

    const getBackgroundImageForInput = (index) => {
        switch (index) {
            case 0:
                return InputCode1;
            case 1:
                return InputCode2;
            case 2:
                return InputCode3;
            case 3:
                return InputCode4;
            case 4:
                return InputCode5;
            default:
                return InputCode1;
        }
    }

    return (
        <React.Fragment>
            <div className={styles.close} onClick={onCloseClick}>
                <img src={CloseButton} alt=""></img>
            </div>
            <div className={styles.inputCodeStage}>
                <div>
                    <div className={styles.introduce}>
                        <h1>INPUT CODE</h1>
                        <p>Please ask the receptionists for your code</p>
                    </div>
                    <div className={styles.inputSection}>
                        {
                            inputCodeIndexes.map(element =>
                                <div style={{
                                    backgroundImage: `url(${getBackgroundImageForInput(element)})`
                                }}
                                     className={styles.inputContainer}
                                     key={element}
                                >
                                    <input type="text"
                                           maxLength="1"
                                           ref={inputRefs[element]}
                                           value={code[element]}
                                           onKeyDown={onKeyDown}
                                           onChange={(event) => onChange(event, element)}
                                           onKeyUp={(event) => onKeyUp(event, element)}
                                    >
                                    </input>
                                </div>
                            )
                        }
                    </div>
                    {
                        displayWarningMessage &&
                        <div className={styles.invalidMessage}>
                            {displayWarningMessage}
                        </div>
                    }
                </div>
                <div className={styles.startButton}>
                    <button disabled={!isValidInput()}
                            onClick={onPlayButtonClick}>
                        <img alt="start" src={StartButton}></img>
                    </button>
                </div>

            </div>
        </React.Fragment>
    )
}