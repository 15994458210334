import React, { useEffect } from 'react';
import AnimationBackground from '../../component/animation-background/animation-background';
import styles from "./manage-page.module.scss";
import ManageGift from "./component/manage-gift";
import { Tab, Tabs } from "@mui/material";
import ViewCode from "./component/view-code";
import { Link, useParams } from "react-router-dom";

const TabType = {
    Empty: 0,
    Gift: 1,
    Code: 2
}

export default function ManagePage() {
    const [value, setValue] = React.useState(TabType.Empty);
    const param = useParams();

    useEffect(() => {
        const page = param['page'];
        if (page === "code") {
            setValue(TabType.Code);
        } else {
            setValue(TabType.Gift);
        }
    }, [param])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <React.Fragment>
            <AnimationBackground>
                <div className={ styles.container }>
                    <Tabs value={ value } onChange={ handleChange }>
                        <Tab className="hidden"
                             label="" { ...a11yProps(TabType.Empty) }/> {/* fake tab with empty data */ }
                        <Tab label="Manage Gifts" { ...a11yProps(TabType.Gift) } to="/manage/gift" component={ Link }/>
                        <Tab label="Code review" { ...a11yProps(TabType.Code) } to="/manage/code" component={ Link }/>
                    </Tabs>
                    <TabPanel value={ value } index={ TabType.Empty }>
                        <div/>
                    </TabPanel>
                    <TabPanel value={ value } index={ TabType.Gift }>
                        <ManageGift/>
                    </TabPanel>
                    <TabPanel value={ value } index={ TabType.Code }>
                        <ViewCode/>
                    </TabPanel>
                </div>
            </AnimationBackground>
        </React.Fragment>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${ index }`,
        'aria-controls': `simple-tabpanel-${ index }`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            style={ { width: `100%`, height: `100%` } }
            hidden={ value !== index }
            id={ `simple-tabpanel-${ index }` }
            aria-labelledby={ `simple-tab-${ index }` }
            { ...other }
        >
            { value === index && children }
        </div>
    );
}
