import styles from "../manage-page.module.scss";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ManageService } from "../../../service/manage.service";
import { HideLoading, OpenErrorAlertAction, ShowLoading } from "../../../context/util.reducer";
import { UpdateUtilContext } from "../../../context/util.context";
import { getViewCodeColumns } from "../conlumn-def";
import NoRows from "./no-rows";
import { Button } from "@mui/material";
import moment from "moment";
import { DateTimeDownloadCodeFormatMoment } from "../../../constants/common-constants";

const csvPrefix = "data:text/csv;charset=utf-8,";

export default function ViewCode() {

    const [data, setData] = useState([]);
    const isFetchedApi = useRef(false);
    const updateUtil = useContext(UpdateUtilContext);
    const apiRef = useGridApiRef();

    useEffect(() => {
        const fetchData = async () => {
            updateUtil(ShowLoading());
            const gameData = await ManageService.getCodesGameSessionAsync();

            if (gameData instanceof String) {
                updateUtil(OpenErrorAlertAction(gameData));
                return;
            }

            setData(gameData);
            updateUtil(HideLoading());
        }

        if (!isFetchedApi.current) {
            fetchData();
            isFetchedApi.current = true;
        }
        // eslint-disable-next-line
    }, [])

    const getCsvContent = () => {
        const rows = apiRef.current.getSortedRows();
        const codeContent = rows.reduce((total, current) => total += `${ current.Code },\n`, "");
        return csvPrefix + codeContent;
    }

    const onClickExport = () => {
        const encoded = encodeURI(getCsvContent());
        const tempLink = document.createElement("a");
        tempLink.setAttribute("href", encoded);
        tempLink.setAttribute("download", `code_${ moment().format(DateTimeDownloadCodeFormatMoment) }`);
        document.body.appendChild(tempLink);
        tempLink.click();
        tempLink.remove();
    }

    return (
        <div className={ styles.tableData }>
            <h2 className={ styles.header }>Code review</h2>
            <div>
                <Button onClick={ onClickExport }
                        style={ { margin: "8px" } } variant="contained" color="primary">Export code</Button>
            </div>
            <DataGrid className={ styles.dataGrid }
                      rows={ data }
                      apiRef={ apiRef }
                      slots={ { noRowsOverlay: NoRows } }
                      columns={ getViewCodeColumns() }
                      getRowId={ (row) => row.Id }
            />
        </div>
    )
}