import {ApiRoute, ManageRoute, mergeRoute} from "./api-route";
import {HttpRequest} from "./http-base";

const manageHost = mergeRoute(process.env.REACT_APP_HOSTNAME, ApiRoute.ManageRoute);

const updateManageGameAsync = async (request) => {
    const manageGameEndPoint = getManageEndpoint(ManageRoute.ManageGame);
    return await HttpRequest.postAsync(manageGameEndPoint, request);
}

const newGameSessionAsync = async () => {
    const manageGameEndPoint = getManageEndpoint(ManageRoute.NewGame);
    return await HttpRequest.getAsync(manageGameEndPoint);
}

const getGameSessionAsync = async () => {
    const manageGameEndpoint = getManageEndpoint(ManageRoute.GetGame);
    return await HttpRequest.getAsync(manageGameEndpoint);
}

const getGiftsGameSessionAsync = async () => {
    const manageGameEndpoint = getManageEndpoint(ManageRoute.GetGifts);
    return await HttpRequest.getAsync(manageGameEndpoint);
}

const getCodesGameSessionAsync = async () => {
    const manageGameEndpoint = getManageEndpoint(ManageRoute.GetCodes);
    return await HttpRequest.getAsync(manageGameEndpoint);
}

const resetCodeGameSessionAsync = async () => {
    const manageGameEndpoint = getManageEndpoint(ManageRoute.ResetCode);
    return await HttpRequest.getAsync(manageGameEndpoint);
}

const getManageEndpoint = (route) => {
    return mergeRoute(manageHost, route);
}

export const ManageApi = {
    updateManageGameAsync,
    newGameSessionAsync,
    getGameSessionAsync,
    getGiftsGameSessionAsync,
    getCodesGameSessionAsync,
    resetCodeGameSessionAsync
}