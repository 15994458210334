import BackgroundChooseCoin from '../../resource/image/Choose/BackgroundChooseCoin.png'
import CoinFirst from '../../resource/gif/coin/coin-bitcoin.gif'
import CoinSecond from '../../resource/gif/coin/coin-etf.gif'
import CoinThird from '../../resource/gif/coin/coin-forex.gif'
import CoinFourth from '../../resource/gif/coin/coin-gold.gif'
import CoinFifth from '../../resource/gif/coin/coin-oil.gif'
import CoinSixth from '../../resource/gif/coin/coin-share.gif'
import styles from './choose-coin-page.module.scss'
import { useContext, useEffect, useState } from 'react'
import {AppContext, UpdateAppContext} from '../../service/app.context'
import {ResetAppContextAction, UpdateAppStageAction, UpdateBackgroundAction} from '../../service/app.reducer'
import { AppStage } from '../../model/app-stage'
import { BackgroundType } from '../../model/background-type'
import CodeService from "../../service/code.service";
import {UpdateUtilContext} from "../../context/util.context";
import {OpenErrorAlertAction} from "../../context/util.reducer";
import AppHelper from "../../helper/app.helper";

export default function ChooseCoinPage() {

    const [coinImages, setCoinImages] = useState([]);
    const appState = useContext(AppContext);
    const updateAppState = useContext(UpdateAppContext);
    const updateUtil = useContext(UpdateUtilContext);

    useEffect(() => {
        let array = [0, 1, 2, 3, 4, 5];
        AppHelper.shuffleArray(array);
        const coinImgs = array.map(x => getImagePath(x));
        setCoinImages(coinImgs);
    }, [])

    const getImagePath = (index) => {
        switch (index) {
            case 0: return CoinFirst;
            case 1: return CoinSecond;
            case 2: return CoinThird;
            case 3: return CoinFourth;
            case 4: return CoinFifth;
            case 5: return CoinSixth;
            default: return ""
        }
    }


    const onCoinClickedAsync = async () => {
        const message = await CodeService.updateCodeGameStatusAsync(appState.code);

        if(message){
            updateAppState(ResetAppContextAction());
            updateUtil(OpenErrorAlertAction(message));
            return;
        }
        updateAppState(UpdateAppStageAction(AppStage.Explode));
        updateAppState(UpdateBackgroundAction(BackgroundType.Explode));
    }

    return (
        <div className={styles.chooseCoin}>
            <div className={styles.chooseOne}>PLEASE CHOOSE ONE</div>
            <div className={styles.containerBackgroundChooseCoin}>
                <div className={styles.itemChooseCoin}>
                    <img src={BackgroundChooseCoin} alt='back ground coin first' />
                    <img onClick={onCoinClickedAsync} className={styles.imgCoin} src={coinImages[0]} alt='coin first' />
                </div>
                <div className={styles.itemChooseCoin}>
                    <img src={BackgroundChooseCoin} alt='back ground coin second' />
                    <img onClick={onCoinClickedAsync} className={styles.imgCoin} src={coinImages[1]} alt='coin second' />
                </div>
                <div className={styles.itemChooseCoin}>
                    <img src={BackgroundChooseCoin} alt='back ground coin third' />
                    <img onClick={onCoinClickedAsync} className={styles.imgCoin} src={coinImages[2]} alt='coin third' />
                </div>
            </div>
        </div>
    );
}