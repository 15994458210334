import { createContext, useReducer } from "react"
import { AppReducer, DefaultAppContext } from "./app.reducer"

const AppContext = createContext(DefaultAppContext);
const UpdateAppContext = createContext(null);

const AppProvider = ({children}) => {
    const [appContext, dispatchAppContext] = useReducer(AppReducer, DefaultAppContext);

    const updateAppContext = (action) => {
        dispatchAppContext(action);
    }

    return (
        <AppContext.Provider value={appContext}>
            <UpdateAppContext.Provider value={updateAppContext}>
                {children}
            </UpdateAppContext.Provider>
        </AppContext.Provider>
    )
}

export default AppProvider

export{
    AppContext,
    UpdateAppContext
}