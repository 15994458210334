import { CodeDto } from "./code-dto";

export class CodeStatusDto {
    Code = new CodeDto();
    IsValid = false;

    constructor (model){
        if(model)
        {
            this.Code = new CodeDto(model.Code);
            this.IsValid = model.IsValid;
        }
    }
}