import styles from "./drawer-code.module.scss";
import Drawer from '@mui/material/Drawer';
import {Button, FormControlLabel, Switch} from "@mui/material";
import {useEffect, useState} from "react";
import AppHelper from "../../helper/app.helper";

export default function DrawerCode({isOpen, setIsOpen, codeData, onModify, isRemoveOption, setIsRemoveOption}) {
    const [codeInput, setCodeInput] = useState("");
    const [originalData, setOriginalData] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if(isOpen){
            setOriginalData([...codeData]); //clone it, use for discard function
            const codeList = codeData.map(x => x.Data);
            setCodeInput(codeList.join('\n'));
            setCount(codeList.length);
        }
    }, [isOpen, codeData])

    const toggleDrawer = () => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
    };

    const onDataChange = (event) => {
        const str = event.target.value;
        setCodeInput(str);
        setCount(convertToList(str).length);
    }

    const convertToList = (rawString) => {
        return rawString.split(/[\s+\n]/).filter(x => x);
    }

    const onSave = () => {
        closeDrawer();
        const codeList = AppHelper.buildCodeListByStringList(convertToList(codeInput));
        onModify(codeList);
    }

    const onDiscard = () => {
        closeDrawer();
        onModify(originalData);
        setOriginalData([]);
    }

    const getCodeString = () => {
        let codeStr = count < 2 ? "code" : "codes";
        return `Total: ${count} ${codeStr}`;
    }

    const closeDrawer = () => setIsOpen(false);

    return (
        <Drawer
            anchor="right"
            open={isOpen}
            onClose={() => toggleDrawer()}
        >
            <div className={styles.container}>
                <div className={styles.title}>
                    <h2>Code editing</h2>
                    <p className={styles.totalCode}>{getCodeString()}</p>
                    <p className={styles.hint}>Each code should separate by space or line break</p>
                </div>
                <div className={styles.textArea}>
                    <textarea placeholder="Enter your code here..."
                              value={codeInput}
                              onChange={onDataChange}
                    />
                </div>
                <div className={styles.buttonContainer}>
                    <FormControlLabel
                        control={<Switch checked={isRemoveOption} onChange={() => setIsRemoveOption(!isRemoveOption)}/>}
                        label="Remove after selected"
                    />

                    <Button variant="contained" onClick={onSave}>Save</Button>
                    <Button variant="contained" color="error" onClick={onDiscard}>Discard & Close</Button>
                </div>
            </div>
        </Drawer>
    );
}