export class GiftDto {
    Id = 0;
    Name = "";
    Image = "";
    Quantity = 0;
    
    constructor (model){
        if(model){
            this.Id = model.Id;
            this.Name = model.Name;
            this.Image = model.Image;
            this.Quantity = model.Quantity;
        } 
    }
  }