import * as CommonConstant from "../constants/common-constants";
import {CodeStatusDto} from "../model/view-model/code-status-dto";
import {CodeApi} from "../http-service/code.api";
import {CodeRequestDto} from "../model/view-model/code-request-dto";
import AppHelper from "../helper/app.helper";
import {Code} from "../model/code";
import {Result} from "../model/result";
import CodeWinnerApi from "../http-service/code-winner.api";

const getGiftOfCodeAsync = async (code) => {
    const request = new CodeRequestDto(code);
    const response = await CodeApi.getCodeStatusAsync(request);

    if (response.HasError) {
        return warningMessageOrGiftResponse(response.ErrorMessage);
    }

    const codeStatus = new CodeStatusDto(response.Data);

    if (!codeStatus.IsValid || !codeStatus.Code.Gift) {
        return warningMessageOrGiftResponse(CommonConstant.InputCodeWarningMessage.InvalidCode);
    }

    if (codeStatus.Code.IsUsed) {
        return warningMessageOrGiftResponse(CommonConstant.InputCodeWarningMessage.CodeIsUsed);
    }

    return warningMessageOrGiftResponse(CommonConstant.InputCodeWarningMessage.NoWarning, codeStatus.Code.Gift);
}


const updateCodeGameStatusAsync = async (code) => {
    const request = new CodeRequestDto(code);
    const response = await CodeApi.updateUsedStatusAsync(request);
    if(response.HasError){
        return response.ErrorMessage;
    }

    const result = new Result(response.Data);

    if(!result.IsSuccess){
        return result.Message;
    }

    return "";
}

const getNextCodeWinnerAsync = async () => {
    const response = await CodeWinnerApi.getNextCodeWinnerAsync();

    if(response.HasError){
        return response.ErrorMessage;
    }

    const result = new Result(response.Data);

    if(!result.IsSuccess) {
        return result.Message;
    }

    const data = new Code(result.Data.Code);
    return data;
}

const generateRandomCodeList = (numberOfCode) => {
    const codes = [];
    for (let i = 0; i < numberOfCode; i++) {
        const code = AppHelper.getRandomNumber(CommonConstant.MaximumCodeLength);
        codes.push(new Code(code));
    }
    return codes;
}

const warningMessageOrGiftResponse = (message, gift) => {
    return {
        warning: message,
        gift: gift
    }
}

const CodeService = {
    updateCodeGameStatusAsync,
    getGiftOfCodeAsync,
    getNextCodeWinnerAsync,
    generateRandomCodeList
}

export default CodeService;
