import {v4 as uuid} from 'uuid';
import {Code} from "../model/code";

const getRandomItemInArray = (array) => {
    if (array && array.length > 0) {
        return array[Math.ceil(Math.random() * array.length - 1)]
    }
    return null;
}

const getRandomNumber = (maxDigits) => {
    const min = Math.pow(10, maxDigits - 1);
    const max = Math.pow(10, maxDigits) - 1;

    return Math.ceil(Math.random() * (max - min)) + min;
}

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]
    }
}

const buildCodeListByStringList = (list) => {
    return list.map(x => new Code(x));
}

const getUUID = () => {
    return uuid();
}

const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
}

const AppHelper = {
    getRandomItemInArray,
    getRandomNumber,
    shuffleArray,
    buildCodeListByStringList,
    getUUID,
    convertToBase64
}

export default AppHelper;