import React, { useContext } from "react"
import styles from './receive-gift-page.module.scss'
import { AppContext, UpdateAppContext } from "../../service/app.context"

import YouWin from '../../resource/image/YouWin/YouWin.png'
import LeftArrow from "../../resource/image/LeftArrow.png"
import BoxTextGift from "../../resource/image/YouWin/BoxText.png";
import { ResetAppContextAction } from "../../service/app.reducer"
import TextFit from "../text-fit/text-fix";

export default function ReceiveGifPage() {

    const appState = useContext(AppContext);
    const updateAppState = useContext(UpdateAppContext);


    const onReturnClick = () => {
        updateAppState(ResetAppContextAction());
    }

    return (
        <React.Fragment>
            <div className={styles.boxContainer}>
                <div className={styles.boxImg}>
                    <div className={styles.youWinContainer}>
                        <img src={YouWin} alt='text you win'></img>
                    </div>
                    <div className={styles.imgGiftContainer}>
                        <img src={appState.gift.Image} alt='gifts'></img>
                    </div>
                    <div className={styles.boxTextContainer}>
                        <img src={BoxTextGift} className={styles.boxText} alt="box" />
                        <TextFit content={appState.gift.Name} calculateBySibling={true} />
                    </div>
                </div>
            </div>
            <div className={styles.return} onClick={onReturnClick}>
                <img src={LeftArrow} alt=""></img>
            </div>
        </React.Fragment>
    )
}