import {ApiRoute, CodeWinnerRoute, mergeRoute} from "./api-route";
import {HttpRequest} from "./http-base";

const codeWinnerHost = mergeRoute(process.env.REACT_APP_HOSTNAME, ApiRoute.CodeWinnerRoute)

const getNextCodeWinnerAsync = async () => {
    const nextCodeWinnerEndPoint = mergeRoute(codeWinnerHost, CodeWinnerRoute.NextCodeWinner);
    return await HttpRequest.getAsync(nextCodeWinnerEndPoint);
}

const CodeWinnerApi = {
    getNextCodeWinnerAsync
}

export default CodeWinnerApi;