export class Response {
    HasError = false;
    Data = null;
    ErrorMessage = "";

    static Error(message) {
        const response = new Response();
        response.ErrorMessage = message;
        response.HasError = true;
        return response;
    }

    static Success(data) {
        const response = new Response();
        response.Data = data;
        return response;
    }
}