import {createContext, useReducer} from "react"
import {DefaultUtilContext, UtilReducer} from "./util.reducer";

const UtilContext = createContext(DefaultUtilContext);
const UpdateUtilContext = createContext(null);

const UtilProvider = ({children}) => {
    const [utilContext, dispatchUtilContext] = useReducer(UtilReducer, DefaultUtilContext);

    const updateUtilContext = (action) => {
        dispatchUtilContext(action);
    }

    return (
        <UtilContext.Provider value={utilContext}>
            <UpdateUtilContext.Provider value={updateUtilContext}>
                {children}
            </UpdateUtilContext.Provider>
        </UtilContext.Provider>
    )
}

export default UtilProvider

export {
    UtilContext,
    UpdateUtilContext
}