import { OpenWarningAlertAction } from "../../context/util.reducer";
import AppHelper from "../../helper/app.helper";
import styles from "./manage-page.module.scss";
import { Button } from "@mui/material";
import React from "react";
import { MaximumUploadSizeByByte, MaximumUploadSizeByMB } from "../../constants/common-constants";

const getIdColumn = () => {
    return {
        field: 'Id',
        headerName: 'No',
        flex: 1,
        renderCell: (params) => {
            const currentRow = params.api.getRowIndexRelativeToVisibleRows(params.row.Id);
            const renderString = currentRow ? currentRow + 1 : 1;
            return (
                <div>
                    { renderString }
                </div>
            )
        }
    }
}

const getGiftIdColumn = () => {
    return {
        field: "GiftId",
        headerName: "",
        flex: 0
    };
}

const getNameColumn = () => {
    return {
        field: 'Name',
        headerName: 'Name*',
        flex: 4,
        editable: true
    };
}

const getQuantityColumn = () => {
    return {
        field: 'Quantity',
        headerName: 'Quantity*',
        flex: 2,
        editable: true,
    };
}

const getUsedColumn = () => {
    return {
        field: 'Used',
            headerName: 'Used',
        flex: 2,
        editable: false
    };
}

const getImageColumn = (updateContext, updateRow) => {
    return {
        field: 'Image',
        headerName: 'Image*',
        flex: 8,
        editable: false,
        renderCell: (param) => {
            const onImageChange = async (event) => {
                const file = event.target.files[0];
                if (file.size > MaximumUploadSizeByByte) {
                    updateContext(OpenWarningAlertAction(`File too big. File size should less than ${MaximumUploadSizeByMB}MB!`))
                    return;
                }
                const base64 = await AppHelper.convertToBase64(file);
                const newRow = {
                    ...param.row,
                    Image: base64
                }
                updateRow(newRow);
            }

            const onImageClick = () => {
                if (param.api.getRowMode(param.row.Id) === "edit") {
                    param.api.stopRowEditMode({ id: param.row.Id });
                }
            }

            return (
                <div className={ styles.imageContainer } title={`Maximum ${MaximumUploadSizeByMB}MB. Type: .png, .jpg, .jpeg`}>
                    <div className={ styles.imageWrapper }>
                        {
                            param.row.Image ?
                                <img src={ param.row.Image } alt="gift"/>
                                : <div className={ styles.uploadMessage }>
                                    <p>Click here to upload an image!</p>
                                    <p>Maximum {MaximumUploadSizeByMB}MB. Type: .png, .jpg, .jpeg</p>
                                </div>
                        }
                        <input onChange={ onImageChange }
                               onClick={ onImageClick }
                               type="file"
                               accept=".png,.jpg,.jpeg"
                        />
                    </div>
                </div>
            )
        }
    };
}

const getActionColumn = (deleteRow) => {
    return {
        field: "Action",
        headerName: "Action",
        flex: 2,
        editable: false,
        renderCell: (param) => {

            const isInEditMode = param.api.getRowMode(param.row.Id) === "edit";

            const editRow = () => {
                if (isInEditMode) {
                    param.api.stopRowEditMode({ id: param.row.Id });
                } else {
                    param.api.startRowEditMode({ id: param.row.Id, fieldToFocus: "Name" });
                }
            }

            return (
                <div className={ styles.actionContainer }>
                    <Button variant="contained" color="primary" onClick={ editRow }>
                        { isInEditMode ? "Done" : "Edit" }
                    </Button>
                    <Button variant="contained" color="error" onClick={ () => deleteRow(param.row.Id) }>
                        Delete
                    </Button>
                </div>
            )
        }
    };
}

export const getManageGiftColumns = (updateContext, updateRow, deleteRow) => {
    return [
        getIdColumn(),
        getGiftIdColumn(),
        getNameColumn(),
        getQuantityColumn(),
        getUsedColumn(),
        getImageColumn(updateContext, updateRow),
        getActionColumn(deleteRow)
    ];
}

export const getViewCodeColumns = () => {
    return [
        getIdColumn(),
        {
            field: 'Code',
            headerName: 'Code',
            flex: 3
        },
        {
            field: 'GiftName',
            headerName: 'Gift',
            flex: 4,
            valueGetter: (params) => params.row.IsUsed ? params.row.GiftName : "",
        },
        {
            field: 'UsedStatus',
            headerName: 'Used',
            flex: 2,
            valueGetter: (params) => params.row.IsUsed ? "✔" : "",
        },
        {
            field: 'UsedTime',
            headerName: 'Used Time',
            flex: 4
        },
    ]
}