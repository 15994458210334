import React, {useState} from "react";
import MenuIcon from "../../resource/image/menu.png";
import DrawerCode from "../../component/drawer/drawer-code";
import RandomPageCommon from "../random-page-common/random-page-common";
import styles from "./random-with-input-page.module.scss"

export function RandomWithInputPage() {
    const [isOpen, setIsOpen] = useState(false);
    const [randomList, setRandomList] = useState([]);
    const [isRemoveOption, setRemoveOption] = useState(true);

    const setToggle = (isToggle) => {
        setIsOpen(isToggle);
    }

    const onRandomListChange = async (newList) => {
        await onListChange(newList);
    }

    const removeWinner = async (winnerCode) => {
        if (isRemoveOption) {
            await onListChange(randomList.filter(x => x.Id !== winnerCode.Id));
        }
    }

    const onListChange = async (newList) => {
        setRandomList(newList);
    }

    const getListToRandom = () => randomList;

    return (
        <React.Fragment>
            <RandomPageCommon getListToRandomFn={getListToRandom} randomCallBack={removeWinner} afterRandomCallBack={removeWinner}/>
            <img src={MenuIcon} alt="" className={styles.menuIcon}
                 onClick={() => setToggle(!isOpen)}></img>
            <DrawerCode codeData={randomList} onModify={onRandomListChange}
                        isOpen={isOpen} setIsOpen={setToggle}
                        isRemoveOption={isRemoveOption} setIsRemoveOption={(isRemove) => setRemoveOption(isRemove)}/>
        </React.Fragment>
    )
}