import { Stack } from "@mui/material";
import React, { useContext } from "react";
import { UtilContext } from "../../../context/util.context";

export default function NoRows() {
    const utilContext = useContext(UtilContext);
    return (
        <Stack height="100%" alignItems="center" justifyContent="center">
            { utilContext.isLoading ? "" : "No data" }
        </Stack>
    )
}