import AppHelper from "../helper/app.helper";

export class Code {
    Id;
    Data;

    constructor(code, id = null) {
        this.Data = code;
        if (!id) {
            this.Id = AppHelper.getUUID();
        }
    }
}