import styles from "../manage-page.module.scss";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ManageGiftModel } from "../../../model/manage-gift.model";
import { UpdateUtilContext } from "../../../context/util.context";
import {
    HideLoading,
    OpenErrorAlertAction,
    OpenSuccessAlertAction,
    OpenWarningAlertAction,
    ShowLoading
} from "../../../context/util.reducer";
import AppHelper from "../../../helper/app.helper";
import { ManageService } from "../../../service/manage.service";
import { Button } from "@mui/material";
import { getManageGiftColumns } from "../conlumn-def";
import NoRows from "./no-rows";
import { ButtonWithConfirm } from "../../../component/button-confirm/button-confirm";

export default function ManageGift() {

    const [data, setData] = useState([]);
    const isFetchedApi = useRef(false);
    const updateUtil = useContext(UpdateUtilContext);
    const apiRef = useGridApiRef();

    useEffect(() => {
        const fetchData = async () => {
            updateUtil(ShowLoading());

            const gameData = await ManageService.getGiftGameSessionAsync();

            if (gameData instanceof String) {
                updateUtil(OpenErrorAlertAction(gameData));
                return;
            }

            setData(gameData.map(gift => {
                return { ...gift, Id: AppHelper.getUUID(), GiftId: gift.Id };
            }));
            updateUtil(HideLoading());
        }

        if (!isFetchedApi.current) {
            fetchData();
            isFetchedApi.current = true;
        }
        // eslint-disable-next-line
    }, [])

    const updateRow = (newRow) => {
        const newData = data.map(x => x.Id === newRow.Id ? newRow : x);
        setData(newData);
    }

    const deleteRow = (rowId) => {
        setData(data.filter(x => x.Id !== rowId));
    }

    const addNewRow = () => {
        const newRow = { ...ManageGiftModel, Id: AppHelper.getUUID() };
        const newData = [...data, newRow];
        setData(newData);
        apiRef.current.startRowEditMode({ id: newRow.Id, fieldToFocus: "Name" });
    }

    const saveAllChangeAsync = async () => {
        for (const element of data) {
            if (apiRef.current.getRowMode(element.Id) === "edit") {
                updateUtil(OpenWarningAlertAction("Make sure you click done/focus out edited row to save all changes!"));
                return;
            }
        }

        updateUtil(ShowLoading());
        const result = await ManageService.sendManageResultAsync(data);
        updateUtil(HideLoading());

        if (result.errorMessage) {
            if (result.isWarning) {
                updateUtil(OpenWarningAlertAction(result.errorMessage))
            } else {
                updateUtil(OpenErrorAlertAction(result.errorMessage));
            }
            return;
        }

        updateUtil(OpenSuccessAlertAction("Save successfully"));
    }

    const resetAsync = async () => {
        updateUtil(ShowLoading());
        const errorMessage = await ManageService.newGameSessionAsync();
        updateUtil(HideLoading());

        if (errorMessage) {
            updateUtil(OpenErrorAlertAction(errorMessage));
            return;
        }

        setData([]);
        updateUtil(OpenSuccessAlertAction("Save successfully"));
    }

    const resetCodeAsync = async () => {
        updateUtil(ShowLoading());
        const errorMessage = await ManageService.resetCodesGameSessionAsync();
        updateUtil(HideLoading());

        if (errorMessage) {
            updateUtil(OpenErrorAlertAction(errorMessage));
            return;
        }

        const newData = data.map(x => {
            return { ...x, Used: "0" }
        });
        setData(newData);

        updateUtil(OpenSuccessAlertAction("Save successfully"));
    }

    const processRowUpdate = (newRow) => {
        updateRow({ ...newRow });
        const validateResult = ManageService.validateGift(newRow);
        if (validateResult.message) {
            updateUtil(OpenWarningAlertAction(validateResult.message));
        }
        return newRow;
    };

    return (
        <div className={ styles.tableData }>

            <h2 className={ styles.header }>Manage gifts</h2>

            <DataGrid
                apiRef={ apiRef }
                className={ styles.dataGrid }
                rows={ data }
                columns={ getManageGiftColumns(updateUtil, updateRow, deleteRow) }
                hideFooter={ true }
                editMode={ "row" }
                rowHeight={ 200 }
                showCellVerticalBorder={ true }
                showColumnVerticalBorder={ true }
                disableRowSelectionOnClick={ true }
                processRowUpdate={ processRowUpdate }
                onProcessRowUpdateError={ (error) => console.log(error) }
                slots={ { noRowsOverlay: NoRows } }
                initialState={ {
                    columns: {
                        columnVisibilityModel: {
                            GiftId: false,
                        },
                    }
                } }
                getRowId={ (row) => row.Id }
            />

            <div className={ styles.buttonActionContainer }>
                <div className={ styles.left }>
                    <Button onClick={ addNewRow } variant="contained" color="primary">Add row</Button>
                    <Button onClick={ saveAllChangeAsync } variant="contained" color="primary">Save all changes</Button>
                </div>
                <div className={ styles.right }>
                    <ButtonWithConfirm confirmFn={ resetCodeAsync } content="Reset code" color="error"/>
                    <ButtonWithConfirm confirmFn={ resetAsync } content="Reset all" color="error"/>
                </div>
            </div>
        </div>
    )
}