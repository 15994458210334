import React, {useContext} from 'react'
import Logo from '../../resource/image/MainPage/Logo.png'
import EnterCodeBtn from '../../resource/image/MainPage/EnterCodeButton.png'
import styles from './start-page.module.scss'
import { UpdateAppContext } from '../../service/app.context'
import { UpdateAppStageAction } from '../../service/app.reducer'
import { AppStage } from '../../model/app-stage'
import Astronaut from '../../resource/image/astronaut.png'
import SpaceShip from '../../resource/image/spaceship.png'

export default function StartPage() {
    const updateAppState = useContext(UpdateAppContext);
    const onEnterCodeClick = () => {
        updateAppState(UpdateAppStageAction(AppStage.InputCode))
    }

    return (
        <React.Fragment>
            <div className={styles.logoGame}>
                <img src={Logo} alt='logo game' />
            </div>
            <div className={styles.enterCodeButton}>
                <button >
                    <img src={EnterCodeBtn} alt='enter code button' onClick={onEnterCodeClick} />
                </button>
            </div>
            <img src={Astronaut} className={styles.astronaut} alt='astronaut'></img>
            <img src={SpaceShip} className={styles.spaceship} alt='space ship'></img>
        </React.Fragment>
    )
}