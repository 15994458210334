export const ApiRoute = {
    CodeRoute: "Code",
    CodeWinnerRoute: "CodeWinner",
    ManageRoute: "Manage"
};

export const CodeRoute = {
    CodeStatus: "CodeStatus",
    UpdateUsedStatus: "UpdateUsedStatus"
}

export const CodeWinnerRoute = {
    NextCodeWinner: "NextCodeWinner"
}

export const ManageRoute = {
    ManageGame: "ManageGame",
    NewGame: "NewGame",
    GetGame: "GetGame",
    GetGifts: "GetGifts",
    GetCodes: "GetCodes",
    ResetCode: "ResetCode"
}

export const mergeRoute = (route1, route2) => `${route1}\\${route2}`;