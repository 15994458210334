import AnimationBackground from "../../component/animation-background/animation-background";
import Logo from "../../resource/image/MainPage/Logo.png";
import DrawNowBtn from "../../resource/image/RandomPage/DrawNow.png";
import Astronaut from "../../resource/image/astronaut.png";
import SpaceShip from "../../resource/image/spaceship.png";
import React, { useContext, useState } from "react";
import styles from "./random-page-common.module.scss";
import AppHelper from "../../helper/app.helper";
import FrameInputCode from "../../resource/image/RandomPage/ContainerCode.png";
import { UpdateUtilContext } from "../../context/util.context";
import { OpenErrorAlertAction } from "../../context/util.reducer";
import { Code } from "../../model/code";
import { ErrorMessage } from "../../constants/common-constants";
import TextFit from "../../component/text-fit/text-fix";

const intervalDuration = 60;
const drawDuration = 3000;
const CodePlaceHolder = "#code";

export default function RandomPageCommon({ getListToRandomFn, afterRandomCallBack, getCodeWinnerFn }) {

    const [isRunning, setIsRunning] = useState(false);
    const [currentChoice, setCurrentChoice] = useState('');
    const updateUtil = useContext(UpdateUtilContext);

    const onStartClick = async () => {
        const finalList = await getListToRandomFn();

        if (finalList.length <= 0) {
            setCurrentChoice(CodePlaceHolder);
            return;
        }
        setIsRunning(true);

        const winner = await getCodeWinner(finalList);

        if (!(winner instanceof Code)) {
            updateUtil(OpenErrorAlertAction(winner ?? ErrorMessage.Unknown));
            setCurrentChoice(CodePlaceHolder);
            setIsRunning(false);
            return;
        }

        const interval = setInterval(function () {
            const randomCodePlaceholder = AppHelper.getRandomItemInArray(finalList);
            setCurrentChoice(randomCodePlaceholder.Data)
        }, intervalDuration);

        setTimeout(() => {
            clearInterval(interval);
            setIsRunning(false);
            setCurrentChoice(winner.Data);
            if (afterRandomCallBack) {
                afterRandomCallBack(winner);
            }
        }, drawDuration);
    }

    const getCodeWinner = async (randomList) => {
        if (getCodeWinnerFn) {
            return await getCodeWinnerFn();
        }

        return AppHelper.getRandomItemInArray(randomList);
    }


    return (
        <React.Fragment>
            <AnimationBackground>
                <div className={ styles.centerPage }>
                    <img className={ styles.logoGame } src={ Logo } alt='logo game'/>
                    <div className={ styles.boxTextContainer }>
                        <img className={ styles.boxText } src={ FrameInputCode } alt=""></img>
                        <TextFit content={currentChoice === '' ? CodePlaceHolder : currentChoice}
                            letterSpacing={4}/>
                    </div>
                </div>
                <div className={ styles.enterCodeButton }>
                    <button onClick={ onStartClick }
                            disabled={ isRunning }>
                        <img src={ DrawNowBtn } alt='draw now button'/>
                    </button>
                </div>
                <img src={ Astronaut } className={ styles.astronaut } alt='astronaut'></img>
                <img src={ SpaceShip } className={ styles.spaceship } alt='space ship'></img>
            </AnimationBackground>
        </React.Fragment>
    )
}