import React from 'react'
import RandomPageCommon from "../random-page-common/random-page-common";
import CodeService from "../../service/code.service";

const numberOfCodePlaceHolder = 15;

export default function RandomPage() {
    const getListToRandom = () => CodeService.generateRandomCodeList(numberOfCodePlaceHolder);

    const getCodeWinnerAsync = async () => await CodeService.getNextCodeWinnerAsync();

    return (
        <RandomPageCommon getListToRandomFn={getListToRandom} getCodeWinnerFn={getCodeWinnerAsync}/>
    )
}