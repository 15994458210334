import {ApiRoute, CodeRoute, mergeRoute} from "./api-route";
import {HttpRequest} from "./http-base";

const codeHost = mergeRoute(process.env.REACT_APP_HOSTNAME, ApiRoute.CodeRoute);

const getCodeStatusAsync = async (request) => {
    const codeStatusEndPoint = getCodeEndpoint(CodeRoute.CodeStatus);
    return await HttpRequest.postAsync(codeStatusEndPoint, request);
}

const updateUsedStatusAsync = async (request) => {
    const updateUsedStatusEndPoint = getCodeEndpoint(CodeRoute.UpdateUsedStatus);
    return await HttpRequest.postAsync(updateUsedStatusEndPoint, request);
}

const getCodeEndpoint = (route) => {
    return mergeRoute(codeHost, route);
}

export const CodeApi = {
    getCodeStatusAsync,
    updateUsedStatusAsync
}