import axios from "axios";
import { HttpCode, NotOkStatuses, OkStatuses, RestMethod } from "./http-constants";
import moment from "moment";
import hashing from "object-hash";
import {Response} from "../model/response";
import {ErrorMessage} from "../constants/common-constants";

const postAsync = async (url, body, queryParams) => {
    return await apiCallCoreAsync(RestMethod.Post, url, body, queryParams);
}

const getAsync = async (url, queryParam) => {
    return await apiCallCoreAsync(RestMethod.Get, url, null, queryParam);
}

const apiCallCoreAsync = async (method, url, body, params) => {
    try {
        const response = await axios({
            method: method,
            url: url,
            data: body,
            params: params,
            headers: buildHeaderRequest()
        });
        if (OkStatuses.some(x => x === response.status)) {
            return Response.Success(response.data);
        }
        if (NotOkStatuses.some(x => x === response.status)) {
            return Response.Error(response.err ?? "The request is dirty. Please check!");
        }
    } catch (error) {
        console.warn(ErrorMessage.ServerError);
        if(error?.response?.status === HttpCode.PayloadTooLarge){
            return Response.Error("Your data send to server is too large. Please reduce the size of image!")
        }
    }

    return Response.Error(ErrorMessage.ServerError);
}

const buildHeaderRequest = () => {
    const sendingTime = moment().valueOf();
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    const hashKey = `${sendingTime}${secretKey}`;
    const hashValue = hashing(hashKey);

    return {
        time : sendingTime,
        auth: hashValue
    }
}

export const HttpRequest = {
    postAsync: postAsync,
    getAsync: getAsync
}