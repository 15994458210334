import LuckyLandingPage from './page/landing/lucky-planet-landing';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import RandomPage from './page/random/random-page';
import ManagePage from './page/manage/manage-page'
import { RandomWithInputPage } from "./page/random-with-input/random-with-input-page";
import UtilProvider from "./context/util.context";
import AppProvider from "./service/app.context";
import AppAlert from "./component/alert/app-alert";
import Loading from "./component/loading/loading";

function App() {
    return (
        <BrowserRouter basename="/">
            <UtilProvider>
                <Routes>
                    <Route exact path='/' element={
                        <AppProvider>
                            <LuckyLandingPage/>
                        </AppProvider>
                    }>
                    </Route>
                    <Route exact path='/manage' element={ < ManagePage/> }></Route>
                    <Route exact path='/manage/:page' element={ < ManagePage/> }></Route>
                    <Route exact path='/random' element={ < RandomPage/> }></Route>
                    <Route exact path='/random-input' element={ < RandomWithInputPage/> }></Route>
                    <Route path="*" element={ <Navigate replace to="/"/> }></Route>
                </Routes>
                <AppAlert/>
                <Loading/>
            </UtilProvider>
        </BrowserRouter>
    );
}

export default App;
