import styles from "./loading.module.scss";
import { useContext } from "react";
import { UtilContext } from "../../context/util.context";

export default function Loading() {

    const utilContext = useContext(UtilContext);

    const getClassName = () => {
        const hidden = !utilContext.isLoading ? "hidden" : "";
        return `${ hidden } ${ styles.container }`
    }

    return (
        <div className={ getClassName() }>
            <div className={ styles.loading }>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            Please wait...
        </div>
    )
}