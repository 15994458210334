import {Severity} from "../constants/common-constants";

const UtilActionType = {
    OpenAlert: 0,
    CloseAlert: 1,
    ShowLoading: 2,
    HideLoading: 3,
}

export const DefaultUtilContext = {
    isOpenAlert: false,
    alertSeverity: Severity.Information,
    alertMessage: "",
    isLoading: false
}

export const OpenInforAlertAction = (message) => {
    return OpenAlertAction(Severity.Information, message);
}

export const OpenSuccessAlertAction = (message) => {
    return OpenAlertAction(Severity.Success, message);
}

export const OpenWarningAlertAction = (message) => {
    return OpenAlertAction(Severity.Warning, message);
}

export const OpenErrorAlertAction = (message) => {
    return OpenAlertAction(Severity.Error, message);
}

const OpenAlertAction = (severity, message) => {
    return {type: UtilActionType.OpenAlert, isOpen: true, severity: severity, message: message};
}

export const CloseAlertAction = () => {
    return {type: UtilActionType.CloseAlert};
}

export const ShowLoading = () => {
    return {type: UtilActionType.ShowLoading};
}

export const HideLoading = () => {
    return {type: UtilActionType.HideLoading};
}

export const UtilReducer = (state, action) => {
    switch (action.type) {
        case UtilActionType.OpenAlert: {
            return {
                ...state,
                isOpenAlert: action.isOpen,
                alertSeverity: action.severity,
                alertMessage: action.message
            }
        }
        case UtilActionType.CloseAlert: {
            return {
                ...state,
                isOpenAlert: false
            }
        }
        case UtilActionType.ShowLoading: {
            return {
                ...state,
                isLoading: true
            }
        }
        case UtilActionType.HideLoading: {
            return {
                ...state,
                isLoading: false
            }
        }
        default:
            return state;
    }
}