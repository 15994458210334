import React, {useContext} from 'react';
import AnimationBackground from '../../component/animation-background/animation-background';
import {AppContext} from '../../service/app.context';
import StartPage from '../../component/start-page/start-page';
import {AppStage} from '../../model/app-stage';
import InputCodeStage from '../../component/input-code-stage/input-code-stage';
import LoadingPage from '../../component/loading-page/loading-page';
import ChooseCoinPage from '../../component/choose-coin-page/choose-coin-page';
import ReceiveGifPage from '../../component/receive-gift-page/receive-gift-page';

export default function LuckyLandingPage() {

    const appState = useContext(AppContext);

    const getStage = () => {
        switch (appState.appStage) {
            case AppStage.MainPage:
                return <StartPage/>
            case AppStage.InputCode:
                return <InputCodeStage/>
            case AppStage.Loading:
                return <LoadingPage/>
            case AppStage.ChooseGift:
                return <ChooseCoinPage/>
            case AppStage.ReceiveGift:
                return <ReceiveGifPage/>
            default:
                return <div></div>
        }
    }
    return (
        <React.Fragment>
            <AnimationBackground>
                {getStage()}
            </AnimationBackground>
        </React.Fragment>
    );
}