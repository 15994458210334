import React, { useContext, useRef } from 'react'
import { AppContext, UpdateAppContext } from '../../service/app.context'
import { BackgroundType } from '../../model/background-type'
import { UpdateBackgroundAction, UpdateAppStageAction } from '../../service/app.reducer'
import styles from './animation-background.module.scss'
import { AppStage } from '../../model/app-stage'

import BackgroundDefault from '../../resource/video/video_bg_default.mp4'
import BackgroundExplode from '../../resource/video/video_bg_explode.mp4'

import FrameLogo from '../../resource/image/FrameLogo.png'
import Frame from '../../resource/image/Frame.png'

import PosterDefault from "../../resource/image/PosterDefault.png"
import PosterExplode from "../../resource/image/PosterExplode.png"

export default function AnimationBackground({ children }) {

    const appState = useContext(AppContext);
    const updateAppState = useContext(UpdateAppContext);
    const videoRef = useRef(null);


    const getVideoSource = () => {
        switch (appState.backgroundType) {
            case BackgroundType.Default:
                return BackgroundDefault;
            case BackgroundType.Explode:
                return BackgroundExplode;
            default:
                return BackgroundDefault;
        }
    }


    const shouldLoop = () => {
        switch (appState.backgroundType) {
            case BackgroundType.Default:
                return true;
            case BackgroundType.Explode:
                return false;
            default:
                return true;
        }
    }

    const getPosterImage = () => {
        switch (appState.backgroundType) {
            case BackgroundType.Default:
                return PosterExplode;
            case BackgroundType.Explode:
                return PosterDefault;
            default:
                return PosterExplode;
        }
    };


    const onVideoEnded = () => {
        switch (appState.backgroundType) {
            case BackgroundType.Explode:
                updateAppState(UpdateBackgroundAction(BackgroundType.ReceiveGifPage));
                updateAppState(UpdateAppStageAction(AppStage.ReceiveGift))
                break;
            default:
                break;
        }
    }

    const onVideoPlay = () => {
        videoRef.current.poster = getPosterImage();
    }

    const getFrameBorder = () => {
        if (appState.appStage === AppStage.MainPage) {
            return FrameLogo;
        }
        return Frame;
    }

    return (
        <React.Fragment>
            <div className={styles.frameBorder}>
                <img src={getFrameBorder()} alt='border' />
            </div>
            <video
                onPlay={onVideoPlay}
                onEnded={onVideoEnded}
                src={getVideoSource()}
                loop={shouldLoop()}
                autoPlay muted preload="auto"
                ref={videoRef}
                poster={PosterDefault}
                className={styles.mainBackground}>
            </video>
            {children}
        </React.Fragment>
    )
}