export const RestMethod = {
    Get: "get",
    Patch: "patch",
    Post: "post",
    Delete: "delete",
    Put: "put"
}

export const HttpCode = {
    Ok: 200,
    Created: 201,
    BadRequest: 400,
    UnAuthorize: 401,
    NotFound: 404,
    PayloadTooLarge: 413,
    ServerError: 500
}

export const OkStatuses = [HttpCode.Ok, HttpCode.Created];
export const NotOkStatuses = [HttpCode.BadRequest, HttpCode.UnAuthorize, HttpCode.NotFound];