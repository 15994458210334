import { ManageGameRequestDto } from "../model/view-model/manage-game-request-dto";
import { ManageApi } from "../http-service/manage.api";
import { Result } from "../model/result";

const sendManageResultAsync = async (giftData) => {

    const validateMessage = validateGiftsData(giftData);

    if (validateMessage) {
        return { errorMessage: validateMessage, isWarning: true };
    }

    const request = ManageGameRequestDto.GetManageGameRequestDto(giftData);
    const response = await ManageApi.updateManageGameAsync(request);

    if (response.HasError) {
        return { errorMessage: response.ErrorMessage };
    }

    const result = new Result(response.Data);

    if (!result.IsSuccess) {
        return { errorMessage: result.Message };
    }

    return "";
}

const validateGiftsData = (gifts) => {
    if (!gifts) {
        return "Something incorrect!";
    }

    let index = 1;
    for (const gift of gifts) {
        const result = validateGift(gift, index);
        if (result.message) {
            return result.message;
        }
        index++;
    }

    return "";
}

const validateGift = (gift, index) => {
    if (!gift.Name) {
        return {
            message: getMessageByProvidedIndex(`Please provide name`, index),
            column: "name"
        };
    }

    const quantity = gift.Quantity;

    if (!quantity) {
        return {
            message: getMessageByProvidedIndex(`Please provide quantity`, index),
            column: "Quantity"
        };
    }

    if (isNaN(quantity)) {
        return {
            message: getMessageByProvidedIndex(`Quantity not a number`, index),
            column: "Quantity"
        };
    }

    const numQuantity = Number.parseInt(quantity);

    if (numQuantity <= 0 || numQuantity > 10000) {
        return {
            message: getMessageByProvidedIndex(`Quantity should in range (1-10000)`, index),
            column: "Quantity"
        };
    }

    if(numQuantity < Number.parseInt(gift.Used)){
        return {
            message: getMessageByProvidedIndex("Quantity must greater than code used", index),
            column: "Quantity"
        }
    }

    if (!gift.Image) {
        return {
            message: getMessageByProvidedIndex(`Please provide image`, index),
            column: "Image"
        };
    }

    return { message: "" };
}

const getMessageByProvidedIndex = (message, index) => {
    if (index) {
        return `${ message } at row index ${ index }`;
    } else return message;
}

const newGameSessionAsync = async () => {
    const response = await ManageApi.newGameSessionAsync();

    if (response.HasError) {
        return response.ErrorMessage;
    }

    const result = new Result(response.Data);

    if (!result.IsSuccess) {
        return result.Message;
    }

    return "";
}

const getGiftGameSessionAsync = async () => {
    const response = await ManageApi.getGiftsGameSessionAsync();

    if (response.HasError) {
        return response.ErrorMessage;
    }

    const result = new Result(response.Data);

    if (!result.IsSuccess) {
        return result.Message;
    }

    return result.Data;
}

const getCodesGameSessionAsync = async () => {
    const response = await ManageApi.getCodesGameSessionAsync();

    if (response.HasError) {
        return response.ErrorMessage;
    }

    const result = new Result(response.Data);

    if (!result.IsSuccess) {
        return result.Message;
    }

    return result.Data;
}

const resetCodesGameSessionAsync = async () => {
    const response = await ManageApi.resetCodeGameSessionAsync();

    if (response.HasError) {
        return response.ErrorMessage;
    }

    const result = new Result(response.Data);

    if (!result.IsSuccess) {
        return result.Message;
    }

    return result.Data;
}

export const ManageService = {
    sendManageResultAsync,
    newGameSessionAsync,
    getGiftGameSessionAsync,
    getCodesGameSessionAsync,
    resetCodesGameSessionAsync,
    validateGift
}