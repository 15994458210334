import { GiftDto } from "./gift-dto";

export class ManageGameRequestDto {
    Gifts = [];

    static GetManageGameRequestDto(gifts) {
        const result = new ManageGameRequestDto();
        result.Gifts = gifts.map(x => new GiftDto({
            Id: +x.GiftId,
            Name: x.Name,
            Quantity: +x.Quantity,
            Image: x.Image
        }));

        return result;
    }
}