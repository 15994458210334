export const MaximumCodeLength = 5;
export const RegexCharacter = new RegExp("^[a-zA-Z0-9 ]$");
export const DateTimeDownloadCodeFormatMoment = "DD_MM_yyyy_HH_mm";
export const MaximumUploadSizeByMB = 1.5;
export const MaximumUploadSizeByByte = MaximumUploadSizeByMB * 1024 * 1024;
export const InputCodeWarningMessage = {
    NoWarning: "",
    InvalidInput: "Invalid input!",
    InvalidCode: "Invalid code. Please try again!",
    CodeIsUsed: "Code is used. Please try another!"
}

export const ErrorMessage = {
    ServerError: "Error when retrieving data from server.",
    Unknown: "Unknown error."
}

export const Severity = {
    Information: "info",
    Success: "success",
    Warning: "warning",
    Error: "error"
}