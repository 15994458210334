import React, { useContext, useEffect, useRef } from 'react'
import LoadingBar from '../../resource/gif/loading-bar.gif'
import styles from './loading-page.module.scss'
import { UpdateAppContext } from '../../service/app.context'
import { UpdateAppStageAction } from '../../service/app.reducer'
import { AppStage } from '../../model/app-stage'

export default function LoadingPage() {

    const updateAppState = useContext(UpdateAppContext);
    const timeRef = useRef(null);

    useEffect(() => {
        timeRef.current = setTimeout(function () {
            updateAppState(UpdateAppStageAction(AppStage.ChooseGift))
        }, 1200);

        return () => {
            clearTimeout(timeRef.current);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    return (
        <React.Fragment>
            <div className={styles.loading}>
                <img src={LoadingBar} alt='loading bar'></img>
                <div className={styles.loadingText}>Loading...</div>
            </div>
        </React.Fragment>
    )
}