import { Button, Popover } from "@mui/material";
import React, { useState } from "react";
import styles from "./button-confirm.module.scss";

export function ButtonWithConfirm ({confirmFn, content, color}) {

    const [btnRef, setBtnRef] = useState(null);

    const onConfirmClicked = () => {
        closePopover();
        confirmFn?.();
    }

    const closePopover = () => {
        setBtnRef(null);
    }

    return (
        <React.Fragment>
            <Button onClick={(e) => setBtnRef(e.currentTarget)}  variant="contained" color={color}>{content}</Button>
            <Popover
                open={Boolean(btnRef)}
                anchorEl={btnRef}
                onClose={closePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className={styles.confirmDialog}>
                    <h3>Please confirm</h3>
                    <div className={styles.moreInfo}>This action can't be undone.</div>
                    <Button variant="contained" color="primary" onClick={closePopover}>Close this dialog</Button>
                    <Button variant="contained" color="error" onClick={ onConfirmClicked }>Confirm</Button>
                </div>
            </Popover>
        </React.Fragment>
    )
}