import { Alert, Button, IconButton, Snackbar } from "@mui/material";
import {useContext} from "react";
import {UpdateUtilContext, UtilContext} from "../../context/util.context";
import {CloseAlertAction} from "../../context/util.reducer";


export default function AppAlert(){

    const utilContext = useContext(UtilContext);
    const updateUtilContext = useContext(UpdateUtilContext);

    const onClose = () => {
        updateUtilContext(CloseAlertAction());
    }

    return (
        <Snackbar onClose={onClose} anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                  open={utilContext.isOpenAlert}
                  autoHideDuration={6000}>
            <Alert severity={utilContext.alertSeverity}
                   variant="filled"
                   action={
                       <Button onClick={onClose} color="inherit" size="small" variant="outlined">
                           CLOSE
                       </Button>
                   }
            >
                {utilContext.alertMessage}
            </Alert>
        </Snackbar>
    )
}