export class Result {
    IsSuccess = false;

    Data = null;

    Message = "";

    constructor(model) {
        if(model) {
            this.IsSuccess = model.IsSuccess;
            this.Message = model.Message;
            this.Data = model.Data;
        }
    }
}