import { GiftDto } from "./gift-dto";

export class CodeDto {
    Id = 0;
    Code = "";
    IsUsed = false;
    UsedTime = null;
    Gift = new GiftDto();
    
    constructor (model){
        if(model){
            this.Id = model.Id;
            this.Code = model.Code;
            this.IsUsed = model.IsUsed
            this.UsedTime = model.UsedTime;
            this.Gift = new GiftDto(model.Gift);
        }
        
    }
}